@import "../../styles/variables";

.description {
    overflow: hidden; }

.faq {
    &.accordion {
        padding: 16px 25px;
        border-radius: 12px;
        background-color: $color-white;
        cursor: pointer;
        margin-bottom: 15px; }
    .icon {
        width: 16px;
        margin-right: 10px;
        vertical-align: baseline; }
    .header {
        font-size: 1.375rem; }
    .body {
        padding-left: 20px;
        overflow: hidden; }
    &.isClosed {
        &:hover {
            background-color: $preview-search-bg; } } }

.dishes {
    margin-bottom: 0.5rem;
    padding-bottom: 1px;
    .header {
        padding: 0.5rem 1rem;
        border: 2px solid transparent;
        box-shadow: $accordion-shadow;
        cursor: pointer;
        background-color: #fff;
        &.red {
            border-color: #ffe5e5;
            background-color: #fff3f3; } }
    &.isOpen {
        background-color: #f1f1f3; } }

@media ( max-width: $mediaMobEdge ) {
    .faq {
        &.accordion {
            padding: 16px; }
        .header {
            font-size: 1rem; }
        .body {
            padding-left: 0; } } }
