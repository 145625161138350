@import "../../styles/variables";

// nouislider имеет опцию cssClasses. Для кастомизации компонента нужно переписать все классы. cssClasses нельзя дополнить, только перезаписать.
// Что бы не перезаписовать каждый ключ cssClasses в js был импортирован и диструктурирован файл со стилями {...s}
// по-этому не удаляйте описание стилей ниже

.target,
.target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    touch-action: none;
    user-select: none;
    box-sizing: border-box; }

.target {
    position: relative; }

.base,
.connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; }

.connects {
    overflow: hidden;
    z-index: 0;
    border-radius: 10px; }

.connect,
.origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat; }

.connect {
    height: 100%;
    width: 100%;
    border-radius: 10px; }

.origin {
    height: 10%;
    width: 10%; }

.textDirectionRtl.horizontal .origin {
    left: 0;
    right: auto; }

.vertical {
    .origin {
        width: 0; } }

.horizontal {
    .origin {
        height: 0; } }

.handle {
    backface-visibility: hidden;
    position: absolute; }

.touchArea {
    height: 100%;
    width: 100%; }

.tap {
    .connect,
    .origin {
        transition: transform 0.3s; } }

.drag * {
    cursor: inherit !important; }

.horizontal {
    height: 40px; }

.horizontal .handle {
    width: 22px;
    height: 40px;
    top: 0px;
    margin-left: auto; }

.vertical {
    width: 18px; }

.vertical {
    .handle {
        width: 28px;
        height: 34px;
        right: -6px;
        top: -17px; } }

.textDirectionRtl.horizontal .handle {
    left: -11px;
    right: auto; }

.target {
    background: #FAFAFA;
    border-radius: 5px; }

.connect {
    background: var(--second-color); }

.draggable {
    cursor: ew-resize; }

.vertical .draggable {
    cursor: ns-resize; }

.handle {
    border: 2px solid var(--second-color);
    border-radius: 5px;
    background-color: $color-white;
    background-repeat: no-repeat;
    background-position: center;
    cursor: col-resize; }

// .active

[disabled] {
    .connect {
        background: #B8B8B8; } }

[disabled].target,
[disabled].handle,
[disabled] .handle {
    cursor: not-allowed; }

.pips,
.pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.pips {
    position: absolute;
    color: #999; }

.value,
.valueNormal,
.valueLarge {
    position: absolute;
    white-space: nowrap;
    text-align: center; }

.valueSub {
    color: #ccc;
    font-size: 10px; }

.marker {
    position: absolute;
    background: #CCC; }

.markerSub {
    background: #AAA; }

.markerLarge {
    background: #AAA; }

.markerNormal {
    background: #AAA; }

.pipsHorizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%; }

.valueHorizontal {
    transform: translate(-50%, 50%); }

.rtl {
    .valueHorizontal {
        transform: translate(50%, 50%); } }

.ltr,
.textDirectionLtr {
    display: block; }

.markerHorizontal.marker {
    margin-left: -1px;
    width: 2px;
    height: 5px; }

.markerHorizontal.markerSub {
    height: 10px; }

.markerHorizontal.markerLarge {
    height: 15px; }

// Vertical layout
.pipsVertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%; }

.valueVertical {
    transform: translate(0, -50%);
    padding-left: 25px; }

.rtl {
    .valueVertical {
        transform: translate(0, 50%); } }

.markerVertical.marker {
    width: 5px;
    height: 2px;
    margin-top: -1px; }

.markerVertical {
    &.markerSub {
        width: 10px; }
    &.markerLarge {
        width: 15px; } }

.tooltip {
    display: block;
    position: absolute;
    background: #fff;
    white-space: nowrap; }

.horizontal {
    .tooltip {
        // transform: translate(-50%, 0)
        // left: 50%
        top: 120%; } }

.vertical {
    .tooltip {
        transform: translate(0, -50%);
        top: 50%;
        right: 120%; } }

.horizontal .origin > .tooltip {
    transform: translate(50%, 0);
    left: auto;
    bottom: 10px; }

.vertical .origin > .tooltip {
    transform: translate(0, -18px);
    top: auto;
    right: 28px; }

// handle
.handleLower {
    right: -22px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='12' viewBox='0 0 6 12'%3E%3Cpath d='M225.733,362.563l-4.3-4.669a1.712,1.712,0,0,1,0-2.179l4.3-4.669a.726.726,0,0,1,1.081,0,.94.94,0,0,1,0,1.229l-4.188,4.529,4.188,4.544a.957.957,0,0,1,0,1.245.76.76,0,0,1-1.081-.031Z' transform='translate(-221.044 -350.805)' fill='currentColor' /%3E%3C/svg%3E"); }

.handleUpper {
    right: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='12' viewBox='0 0 6 12'%3E%3Cpath d='M222.355,362.563l4.3-4.669a1.712,1.712,0,0,0,0-2.179l-4.3-4.669a.726.726,0,0,0-1.081,0,.94.94,0,0,0,0,1.229l4.188,4.529-4.188,4.544a.957.957,0,0,0,0,1.245.76.76,0,0,0,1.081-.031Z' transform='translate(-221.044 -350.805)' fill='currentColor' /%3E%3C/svg%3E"); }

.horizontal {
    .handleUpper {
        display: block;
        .tooltip {
            transform: translate(calc(-100% + 22px), 0); } } }
