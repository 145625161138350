.block {
    width: 86px;
    height: 86px;
    position: relative;
    margin-bottom: 10px;
    margin-right: 13px;
    cursor: pointer;
    &:hover {
        .check {
            opacity: 0.3; } }
    &.active {
        .check {
            opacity: 1; } } }

.outline {
    width: 100%;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px; }

.accent {
    width: 77%;
    height: 77%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px; }

.second {
    width: 64%;
    height: 64%;
    position: absolute;
    bottom: 0%;
    right: 0%;
    border-radius: 6px; }

.check {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0; }

.checkSVG {
    display: block;
    width: 100%;
    height: 100%; }
