@import "../../../styles/variables";

.product {
    width: 100%; }

.saving {
    color: $color-green-dark; }

.icon {
    display: block;
    width: 20px;
    height: 24px;
    opacity: 0.3;
    cursor: pointer; }
