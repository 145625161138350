@import "../../../styles/variables";

.card {
    position: relative;
    padding: 1rem;
    background-color: $background-input;
    border-radius: 12px;
    height: 145px;
    overflow: hidden;
    margin-bottom: 1rem;
    border: 2px solid transparent;
    cursor: pointer;
    &:hover {
        border-color: $color-blue-light;
        .remove {
            display: block; } }
    &.active {
        border-color: $color-blue-dark;
        cursor: default; }
    &:last-child {
        margin-bottom: 0; } }

.remove {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    opacity: 0.6;
    &:hover {
        opacity: 1; } }

.title {
    white-space: nowrap;
    height: 1.5rem; }

.description {
    font-size: 0.875rem;
    line-height: 1rem;
    height: 2rem;
    color: $color-gray;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }

.icon {
    display: block;
    width: 32px;
    height: 24px;
    margin-left: 14px;
    margin-right: 5px;
    background-size: contain; }
