@import "../../../styles/variables";
.item {
    background-color: $color-white;
    max-width: 100%;
    width: 348px;
    border-radius: 16px;
    box-shadow: $block-shadow;
    position: relative;
    user-select: none;
    padding: 16px 16px 12px;
    margin-bottom: 16px; }

.text {
    color: $color-gray;
    margin-bottom: 5px;
    line-height: 1.25;
    font-weight: 300;
    height: 80px;
    overflow: hidden; }

.link {
    margin-bottom: 5px;
    display: block; }

.label {
    text-align: center;
    color: $color-gray;
    font-weight: 500;
    margin-bottom: 0; }

.gap {
    padding-top: 29px; }
