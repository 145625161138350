@import "../../../styles/variables";

.item {
    background-color: $color-white;
    max-width: 100%;
    width: 100%;
    border-radius: 16px;
    box-shadow: $block-shadow;
    position: relative;
    user-select: none;
    padding: 16px 16px 12px;
    margin-bottom: 16px; }

.label {
    font-weight: 500;
    color: $color-gray; }

.dot {
    position: relative;
    padding-left: 16px;
    &::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-color: var(--dot-color);
        border-radius: 50%; } }
