@import "../../../styles/variables";

.item {
    background-color: $color-white;
    max-width: 100%;
    width: 360px;
    border-radius: 12px;
    box-shadow: $block-shadow;
    margin-bottom: 30px;
    position: relative;
    .body {
        display: block;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 12px;
        overflow: hidden; }
    a {
        color: inherit; } }

.label {
    font-weight: 400;
    color: $color-gray; }

.title {
    padding-left: 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #d1d3d7; }
    &.active {
        &::before {
            background: $color-green; } } }


@media ( max-width: $mediaMobEdge ) {
    .item {
        width: 100%;
        margin-bottom: 20px;
        .body {
            padding: 20px; } } }
