@import "../../../styles/variables";

.item {
    border-radius: 12px;
    box-shadow: $block-shadow;
    padding: 1.5rem;
    background-color: $color-white; }

.label {
    color: $color-gray; }
