@import "../../../styles/variables";

.spinner {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: $maxIndex;
    background-color: rgba($color-white, 0.51);
    &.transparent {
        background: transparent; } }

.modal {
    border-radius: 40px; }

.item {
    border-radius: 12px; }

.progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border-radius: 50%;
    background: #fff;
    font-size: 14px; }

.large {
    width: 100px;
    height: 100px; }
