@import "../../../styles/variables";

.label {
    color: $color-gray; }

.description {
    color: $color-gray; }

.icon {
    display: block;
    width: 20px;
    height: 24px;
    opacity: 0.3;
    cursor: pointer; }
