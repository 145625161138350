@import "../../../styles/variables";

.nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }

.navContent {
    height: 100%; }

.logo {
    display: block;
    margin-left: 16px;
    margin-top: 20px; }

.logoImg {
    height: 36px;
    svg {
        display: block; } }

.menu {
    width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    overflow: hidden auto; }

.menuInner {
    width: 100%; }

.submenu {
    width: 228px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 80px;
    z-index: 1;
    .navItem {
        width: 100%; } }

.navItem {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0.5rem 1.25rem;
    cursor: pointer;
    &:hover,
    &.active {
        .icon {
            background-color: currentColor; } } }

.navTitle {
    font-size: 0.875rem;
    color: $color-white;
    padding-left: 0.5rem; }

.droptdownItem {
    position: relative; }

.icon {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    padding: 11px;
    transition: background $transition; }

.svgIcon {
    display: block;
    width: 18px;
    height: 18px;
    fill: $color-white; }

.counter {
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: $color-white;
    font-size: 0.5rem;
    line-height: 12px;
    text-align: center;
    font-weight: bold; }

@media ( max-width: $mediaMobEdge ) {
    .nav {
        height: 60px;
        background-color: var(--accent-color);
        z-index: $maxIndex; }

    .logo {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 0;
        padding: 0 20px; }

    .logoImg {
        height: 30px; }

    .menuButton {
        padding: 0 20px; }

    .navItem {
        width: 100%;
        padding: 0 20px;
        margin: 0.5rem 0;
        &:hover, {
            .icon {
                background-color: transparent; } } }

    .counter {
        left: 22px; }

    .menu {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--accent-color); }

    .submenu {
        position: static;
        width: 100%;
        height: auto; } }
