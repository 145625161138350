.wrapper {
    display: flex;
    height: 100vh;
    overflow: auto;
    padding: 20px; }

.bg {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: center / cover no-repeat; }
