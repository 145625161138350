@import "../../styles/variables";
$smallText: 0.875rem;
$descriptionColor: #8e8e93;

.delimeter {
    color: $color-gray;
    padding: 0 12px; }

.orderTitle {
    font-weight: 600; }

.smallText {
    font-size: $smallText; }

.field {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative; }

.chevron::after {
    content: '';
    display: block;
    width: 8px;
    height: 10px;
    position: absolute;
    top: 100%;
    left: 50%;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.979' height='12.127' viewBox='0 0 6.979 12.127'%3E%3Cpath id='chevron' d='M2.557,1.121A.79.79,0,0,0,3.124.889l5.318-5.2a.878.878,0,0,0,.28-.636.828.828,0,0,0-.28-.629l-5.318-5.2a.79.79,0,0,0-.567-.232.8.8,0,0,0-.813.807.864.864,0,0,0,.246.588L6.781-4.936,1.989-.273a.834.834,0,0,0-.246.588A.8.8,0,0,0,2.557,1.121Z' transform='translate(-1.743 11.006)' fill='%23a3a3a3'/%3E%3C/svg%3E") center / contain no-repeat;
    transform: translateX(-50%) translateY(4px) rotate(90deg); }

.item {
    background-color: $color-white;
    max-width: 100%;
    width: 324px;
    height: 190px;
    border-radius: 12px;
    box-shadow: $block-shadow;
    margin-bottom: 30px;
    position: relative;
    // z-index: 1
    user-select: none;
    &.scheduleItem {
        width: 678px; }
    .body {
        display: block;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 12px;
        overflow: hidden; }
    a {
        color: inherit; }
    &.square {
        width: 240px;
        height: 240px; }
    &.flexBottom {
        .body {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-end; } }
    &.short {
        height: auto;
        min-height: 128px;
        .body {
            padding: 1.5rem 1.5rem 1rem; }
        &.dishItem {
            .body {
                padding: 1rem 1.5rem; } } }
    &.marketItem {
        width: 100%;
        margin-bottom: 1rem; }
    &:hover {
        z-index: 2;
        .menuWrapper {
            opacity: 1;
            visibility: visible; } } }

.menuWrapper {
    border-bottom-left-radius: inherit;
    border-top-right-radius: inherit;
    opacity: 0;
    visibility: hidden; }

.itemInnerList {
    position: relative; }

.addItem {
    color: $color-white;
    .body {
        padding: 34px; } }

.clickable {
    cursor: pointer; }

.title {
    font-weight: 400;
    color: $color-gray; }

.blockTitle {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis; }

.btnWrapper {
    padding-top: 40px; }

.qrImg {
    display: block;
    width: 76px; }

.videoImg {
    display: block;
    width: 76px;
    height: 76px;
    object-fit: cover;
    object-position: center; }

.item {
    .qrDownload {
        font-size: 0.8125rem;
        color: var(--accent-color);
        &:hover {
            color: var(--second-color); } } }

.mask {
    margin: 0;
    padding: 0;
    border: 0;
    pointer-events: none; }

.image {
    display: block;
    width: 100%;
    cursor: pointer;
    background: $color-gray-light center / cover no-repeat;
    border-radius: 8px;
    border: 1px solid #F0F2F8;
    margin-bottom: 0;
    &::before {
        content: '';
        display: block; } }

.empty {
    background-size: 26px; }

.logo {
    &::before {
        padding-bottom: 100%; } }

.bg {
    &::before {
        padding-bottom: 50%; } }

.icon {
    position: absolute;
    top: 23px;
    right: 24px; }

.iconSvg {
    width: 22px;
    height: auto;
    max-height: 22px;
    display: block;
    fill: $color-white;
    path {
        stroke: $color-white; } }

.iconRight {
    .iconSvg {
        margin-left: auto; } }

.avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;
    border: 1px solid #f1f1f3;
    background-color: $color-gray-light; }

.fitImg {
    object-fit: cover;
    width: 100%;
    height: 100%; }

.containImg {
    object-fit: contain;
    width: 100%;
    height: 100%; }

.emptyImg {
    height: 100%;
    background: $color-gray-light center/65px no-repeat; }

.avatarSVG {
    display: block;
    width: 40px;
    height: 100%;
    margin: auto;
    fill: #e0e0e0; }

.checker {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 44px;
    height: 44px;
    padding: 10px;
    opacity: 0.4;
    transition: opacity 0.2s;
    z-index: 1;
    &.checked,
    &:hover {
        opacity: 1; } }

.checkIcon {
    margin-left: 10px;
    margin-top: 5px; }

// .short
//     .titleAddDish
//         position: absolute
//         bottom: 0
//         left: 1.5rem

.titleAddDish {
    // white-space: pre-wrap
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis; }

.smallTitle {
    font-size: 1rem;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    line-height: 1.5; }

.smallDescription {
    font-size: 0.875rem;
    color: $descriptionColor;
    overflow: hidden;
    margin-bottom: 1rem; }

.widgets {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    font-weight: 700; }

.labelMark {
    // position: absolute
    // top: 12px
    // left: 12px
    background-color: $color-white;
    padding: 4px 10px;
    border-radius: 1rem;
    font-size: 0.875rem;
    color: $color-initial; }

.counterHolder {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 24px;
    right: 24px;
    color: $color-initial;
    text-align: center;
    line-height: 50px;
    z-index: 1;
    &::before {
        content: '';
        display: block;
        position: absolute;
        // inset: -4px
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
        background-color: rgba($color-white, 0.35);
        border-radius: 50%;
        z-index: -1; } }

.counterMark {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    border-radius: 50%; }

// dishes
.publishHide {
    opacity: 0.4; }

.dishTitle {
    padding-left: 0.875rem;
    padding-right: 1rem;
    font-size: 0.875rem; }

.dishDescription {
    height: 34px;
    line-height: 1.2; }

.dishPrice {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 700; }

.dishHasImage {
    &.body {
        display: flex;
        flex-wrap: wrap; }
    .dishContent {
        flex: 0 0 calc(100% - 106px);
        max-width: calc(100% - 106px); }
    .dishImage {
        flex: 0 0 116px;
        max-width: 116px;
        height: 116px;
        border-radius: 10px;
        margin-top: -10px;
        margin-bottom: -10px;
        margin-right: -18px;
        margin-left: 8px;
        overflow: hidden; } }

.publishStatusImg {
    display: flex;
    flex-wrap: wrap;
    width: 116px;
    height: 116px;
    position: absolute;
    top: 6px;
    right: 6px;
    align-items: center;
    justify-content: center; }

.publishStatus {
    position: absolute;
    right: 4%;
    bottom: 10%;
    width: 64px;
    height: 43px; }

.fillWhite {
    fill: #ffffff; }

.fillGray {
    fill: #dddddd; }

// news and push notifications
.itemNews {
    height: auto;
    .body {
        padding: 0 1.5rem 1rem; } }

.newsImage {
    height: 9rem;
    margin-bottom: 1rem;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden; }

.newsIcon {
    margin-right: 5px; }

.newsDescription {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 72px;
    margin-bottom: 0; }

// orders
.order {
    background-color: $color-white;
    box-shadow: $block-shadow;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 1.25rem;
    position: relative; }

// feedback
.itemFeedback {
    height: auto; }

.feedbackDescription {
    font-size: $smallText;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    color: $descriptionColor;
    font-weight: 700;
    min-height: 42px; }

// cards
.card {
    background-color: $color-white;
    box-shadow: $block-shadow;
    border-radius: 12px;
    padding: 1.25rem 1.875rem;
    margin-bottom: 1rem; }

.cardsTitle {
    margin-bottom: 0;
    line-height: 1.5;
    font-weight: 600; }

.cardsText {
    margin-bottom: 0; }

.blue {
    color: $color-blue-dark; }

.gray {
    color: $color-gray; }

// schedule
.schedule {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center; }

.scheduleIcon {
    display: block;
    margin: 14px auto; }

.time {
    margin: 0;
    font-size: 14px; }

.day {
    text-align: center;
    padding: 0 2px;
    flex: 0 0 (100%/7);
    max-width: (100%/7); }

@media ( max-width: $mediaMobEdge ) {
    .addItem {
        .body {
            padding: 20px 20px 8px; } }

    .item {
        height: auto;
        width: 100%;
        margin-bottom: 20px;
        .body {
            padding: 20px; }
        &.square,
        &.short,
        &.scheduleItem {
            min-height: 0;
            width: 100%; } }

    .menuWrapper {
        opacity: 1;
        visibility: visible; }

    .newsDescription {
        display: block;
        height: auto; }

    .feedbackDescription {
        min-height: 0;
        display: block; }

    .order {
        padding: 20px; }

    .titleAddDish {
        white-space: normal; }

    .short {
        .titleAddDish {
            position: static; } }

    .iconRight {
        .iconSvg {
            margin-left: 0; } }

    .item:not(.dishItem) {
        &.short {
            .body {
                padding: 1.25rem 1.25rem 0.5rem; } } }

    .labelMark {
        left: auto;
        right: 12px; } }
