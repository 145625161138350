@import "../../styles/variables";

.inactive {
    pointer-events: none; }

.group {
    margin-bottom: 1rem;
    position: relative;
    border-radius: 12px;
    background-color: $background-input;
    .input:focus ~ .label,
    .input:not(:placeholder-shown) ~ .label,
    textarea:focus ~ .label,
    textarea:not(:placeholder-shown) ~ .label,
    .labelSelect,
    .labelPicker {
        top: 0px;
        font-size: 0.875rem;
        padding-top: 7px;
        width: calc(100% - 44px);
        text-align: left; }
    .input,
    .textarea {
        border-radius: 12px; } }

.clearableGroup {
    margin-right: 40px; }

.label {
    display: block;
    position: absolute;
    left: 22px;
    top: 18px;
    pointer-events: none;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    color: #a3a3a3;
    transition: 0.1s;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis; }

.readonly {
    background-color: $background-input-disabled;
    color: $background-input-color-disabled; }

// textfield & textarea
.input,
.textarea {
    display: block;
    width: 100%;
    -webkit-appearance: none;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    padding: 26px 22px 9px;
    font-size: 1rem;
    line-height: 25px;
    &:focus {
        outline: 0; } }

.textarea {
    height: 210px;
    resize: none;
    & + .label {
        background-color: $background-input; }
    &.readonly {
        color: $color-gray;
        cursor: default;
        .label {
            background-color: $background-input-disabled; } } }

.notice {
    position: absolute;
    bottom: -7px;
    margin: 0;
    font-size: 0.875rem;
    pointer-events: none;
    padding: 0 22px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

// checkboxes
.checkbox {
    position: relative; }

.checkboxInput {
    display: none; }

.checkboxLabel {
    position: relative;
    padding-left: 32px;
    display: block;
    cursor: pointer;
    user-select: none;
    font-size: 1rem; }

.switchLabel {
    padding-left: 0;
    font-size: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }

.checkboxIcon {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    svg {
        display: block; } }

.switchIcon {
    margin-right: 10px; }

// file
.labelFile {
    cursor: pointer;
    margin-bottom: 0; }

.title {
    text-align: center; }

.info {
    text-align: center;
    color: #a3a3a3;
    font-size: 1rem;
    line-height: 1.5;
    white-space: pre-wrap;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 36px; }

.labelFile {
    background: center / cover no-repeat;
    @include dashed-image-loader;
    &.round {
        display: block;
        width: 236px;
        height: 236px;
        border-radius: 50%; }
    &.defaultImg {
        display: block;
        width: 100%;
        height: 166px;
        border-radius: 1rem;
        padding: 0 36px; } }

// select
.selectIcon {
    display: inline-block;
    width: 24px;
    height: 18px;
    background: center / contain no-repeat;
    vertical-align: middle;
    margin-right: 5px; }

.description {
    width: 100%;
    color: $color-gray;
    margin-bottom: 1rem;
    font-weight: 300;
    font-size: 14px; }

// video editor
.editor {
    display: block;
    position: relative;
    background-color: #F2F2F2;
    text-align: center;
    border-radius: 12px;
    border: 2px dashed transparent;
    &::after {
        content: '';
        display: block;
        padding-bottom: 56.25%; }
    &.dragIn {
        border-color: var(--second-color); } }

.videoContent {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    .info {
        height: auto; } }

.eye {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    cursor: pointer;
    svg {
        display: block;
        width: 20px;
        height: 100%;
        margin: auto;
        fill: #a3a3a3; } }

.requirements {
    white-space: pre-wrap;
    font-weight: 500;
    color: #a3a3a3; }

// datepicker
.datepicker {
    background-color: #000; }

@media ( max-width: $mediaMobEdge ) {
    .switchLabel {
        font-size: 1rem; }

    .info {
        padding: 0; } }
