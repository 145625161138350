@import "../../styles/variables";

.close {
    border: 0;
    background-color: transparent;
    padding: 0;
    position: absolute;
    top: 50px;
    left: -35px;
    z-index: #{$maxIndex + 1};
    width: 70px;
    height: 70px;
    svg {
        width: 100%;
        height: 100%; }
    &:focus {
        outline: 0; }
    &.topRight {
        left: auto;
        right: 8px;
        top: 8px; } }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    z-index: $maxIndex;
    background: rgba(0,0,0,0);
    transition: background 0.5s;
    text-align: right; }

.overlayFast {
    transition: background 0.2s; }

.contentClear {
    background: none;
    height: 100%;
    max-width: 100%;
    outline: 0; }

.content {
    display: block;
    height: 100%;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    background-color: $color-white;
    text-align: left;
    position: relative;
    margin-left: auto; }

.fixedContent {
    outline: 0;
    max-width: 90%;
    width: 1000px;
    height: 100%;
    display: inline-block; }

.innerContent {
    padding: 60px;
    height: 100%;
    overflow: auto; }

.description {
    font-size: 1rem;
    font-weight: 300;
    white-space: pre-wrap;
    color: $color-gray; }

.secureModal {
    width: 660px;
    .innerContent {
        display: flex;
        flex-direction: column; } }

.contentNotice {
    background-color: $color-white; }

.contentCenter {
    background-color: $color-white;
    width: 600px;
    max-width: 100%;
    border-radius: 12px;
    margin: auto;
    position: relative;
    .innerContent {
        padding: 60px 35px 40px;
        text-align: center; } }

@media ( max-width: $mediaMobEdge ) {
    .close {
        left: auto;
        top: 0;
        right: 0;
        width: 60px;
        height: 60px; }

    .content {
        overflow: hidden;
        border-radius: 28px;
        height: auto; }

    .innerContent {
        padding: 20px;
        height: auto; }

    .description {
        font-size: 0.875rem; }

    .fixedContent {
        max-width: 100%;
        height: auto; }

    .contentClear {
        height: auto; }

    .overlay {
        padding: 20px 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        overflow-x: hidden; }

    .contentCenter {
        .innerContent {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px; } } }
