@import "../../../styles/variables";

.breadcrumbs {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px; }

.breadcrumbsItem {
    color: $color-gray;
    a {
        color: currentColor; }
    &:last-child {
        .divider {
            display: none; } } }

.dishes {
    font-size: 1.25rem;
    .breadcrumbsItem {
        color: inherit;
        &:last-child {
            color: $color-blue-dark; } } }

.divider {
    margin: 0 8px; }

@media ( max-width: $mediaMobEdge ) {
    .breadcrumbs {
        margin-bottom: 25px; } }
