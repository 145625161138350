@import "../../styles/variables";

.pointer {
    cursor: pointer; }

.dropdown {
    min-width: 17.25rem;
    font-size: 1.125rem;
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        border-style: solid;
        border-width: 9px 5px 0px;
        border-color: #fff transparent transparent; } }

.dropdownInput {
    display: none; }

.icon {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 1rem;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain; }

.value {
    // background: $blue-linear-gradient
    color: $color-white;
    padding: 1.25rem 1.75rem;
    border-radius: 12px;
    @include gradientToRight(var(--accent-color),var(--second-color));
    .icon {
        background-color: $color-white; } }

.disabled {
    .value {
        background: $gray-linear-gradient; } }

.options {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    color: $color-blue-dark;
    padding: 1.25rem 1.75rem;
    background-color: $color-white;
    box-shadow: $dropdown-shadow;
    border-radius: 12px;
    z-index: 1;
    overflow: hidden;
    .icon {
        background-color: $color-blue-dark; }
    .item {
        .text {
            transition: transform $transition; }
        &:hover {
            .text {
                transform: translateX(-6px); } } } }

.item {
    cursor: pointer;
    margin-bottom: 0.75rem;
    &:last-child {
        margin-bottom: 0; } }

.red {
    color: $color-red;
    .icon {
        background-color: $color-red; } }

.selected {
    color: $color-gray;
    pointer-events: none;
    .icon {
        background-color: $color-gray; } }
