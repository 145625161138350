@import "../../../styles/variables";

.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

.list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end; }

.link {
    display: block;
    padding: 0 0.5rem;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    min-width: 40px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    margin: 0 0.25rem;
    &:hover {
        background-color: var(--accent-color);
        color: $color-white; }
    &.active {
        background-color: var(--second-color);
        color: $color-white;
        pointer-events: none; } }

.arrow {
    display: block;
    border-radius: 4px;
    background-color: var(--accent-color);
    color: $color-white;
    width: 40px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    &:hover {
        background-color: var(--second-color);
        color: $color-white; }
    &.disabled {
        background-color: #bababa;
        color: $color-white;
        pointer-events: none; } }

@media ( max-width: $mediaMobEdge ) {
    .arrow {
        width: 24px;
        line-height: 24px; }

    .link {
        min-width: 24px;
        line-height: 24px;
        padding: 0 0.125rem; } }
