@import "../../styles/variables";

.main {
    height: 100vh;
    transition: background 0.5s;
    overflow: hidden;
    background-color: var(--accent-color); }

a {
    color: var(--second-color);
    &:hover {
        color: var(--second-color); } }
