@import "../../../styles/variables";

.impression {
    padding: 5px;
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 8px; }
    &:nth-child(even) {
        color: var(--color-second); }
    &:nth-child(odd) {
        color: var(--color-accent); } }

.icon {
    margin-right: 10px; }

.item {
    background-color: $color-white;
    max-width: 100%;
    width: 348px;
    border-radius: 16px;
    box-shadow: $block-shadow;
    position: relative;
    user-select: none;
    padding: 24px 16px 12px;
    margin-bottom: 16px; }

.label {
    font-weight: 500;
    color: $color-gray;
    text-align: center;
    margin-bottom: 0; }

.title {
    margin-bottom: 0;
    color: $color-gray; }

.count {
    font-weight: 600;
    font-size: 14px; }

.percent {
    font-size: 12px;
    color: #b7b7b7;
    top: -0.25em;
    margin-left: 7px;
    &.green::before {
        content: '▲ ';
        color: #00BA16; }
    &.red::before {
        content: '▼ ';
        color: #E83131; } }
