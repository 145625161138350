@import "../../../styles/variables";

.list {
    box-shadow: 2px 3px 8px #e8e8e8;
    border-radius: 1rem; }

.button {
    height: 58px;
    padding: 0 50px;
    border-radius: 1rem;
    color: inherit;
    text-transform: none;
    &.active {
        color: $color-white; } }
