:root {
    --background-body: #fff;
    --font: "SF Pro Display";
}

body {
    background-color: var(--background-body);
    font-family: var(--font), "Arial", "sans-serif";
    color: #333;
}

a {
    color: #0780E3;
}

a:hover {
    text-decoration: none;
}

.ReactModal__Overlay--after-open {
    background: rgba(0,0,0,0.4);
}

.ReactModal__Overlay--before-close {
    background: rgba(0,0,0,0);
}

@media (max-width: 767.98px) {
    body {
        font-size: 14px;
    }
}