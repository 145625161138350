@import "../../../styles/variables";

.item {
    background-color: $color-white;
    box-shadow: 0 0 0 rgba(#000, 0.02);
    padding: 30px;
    min-height: 240px;
    border-radius: 12px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between; }

.body {
    width: 100%; }

@media ( max-width: $mediaMobEdge ) {
    .item {
        padding: 20px 20px 4px;
        min-height: 0; } }
