@import "../../../styles/variables";

.popular {
    background-color: $color-white;
    max-width: 100%;
    width: 348px;
    border-radius: 16px;
    box-shadow: $block-shadow;
    position: relative;
    user-select: none;
    padding: 16px 16px 12px;
    margin-bottom: 16px;
    text-align: center; }

.img {
    width: 60px;
    height: 60px;
    margin: 0 auto 1px;
    background: center / cover no-repeat;
    border-radius: 50%; }

.percent {
    margin-bottom: 6px;
    color: #B7B7B7;
    font-size: 12px;
    &::before {
        content: '▲';
        color: #00BA16; } }

.title {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    margin-bottom: 8px; }

.label {
    font-weight: 500;
    margin-bottom: 0;
    color: $color-gray;
    line-height: 1.25;
    min-height: 40px; }
