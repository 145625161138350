@import "../../styles/variables";

.disabled {
    pointer-events: none;
    opacity: 0.5;
    transform: scale(0.7); }

.button {
    border-radius: 12px;
    border: 0;
    transition: transform .1s;
    color: $color-white;
    &:focus {
        outline: 0; }
    &:active {
        transform: scale(1.05); } }

.default {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    text-transform: uppercase;
    padding: 0 30px;
    z-index: 0;
    &::before {
        content: '';
        display: block;
        width: 120%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -10%;
        z-index: -1; } }

// colors
.blue {
    &::before {
        background-image: $blue-linear-gradient; } }

.gray {
    &::before {
        background-image: $gray-linear-gradient; } }

.custom {
    &::before {
        background-image: linear-gradient(to right, var(--second-color), var(--accent-color)); } }

// empty manage blocks button
.empty {
    width: 100%;
    border: 2px dashed $color-gray;
    color: $color-gray;
    padding: 10px;
    text-transform: none;
    border-radius: 0;
    transition: transform 0.1s;
    &:hover {
        border-color: $color-blue-dark;
        transform: scale(0.95); } }

// sizes
.large {
    width: 100%;
    height: 56px; }

.save {
    padding: 16px 58px; }

.loadVideo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 300px;
    max-width: 100%;
    height: 56px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer; }

// orders cancel buttons
.tag {
    min-width: 60px;
    padding: 6px 12px;
    background-color: $color-blue-dark;
    margin-right: 5px;
    margin-bottom: 8px; }

// product line buttons
.controller {
    padding: 0;
    width: 54px;
    border-radius: 12px;
    margin-bottom: 6px;
    position: relative;
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: center no-repeat; } }

.minus {
    background: $minus-linear-gradient;
    &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M2.923,8a10.061,10.061,0,0,0,10-10A10.071,10.071,0,0,0,2.914-12,10.052,10.052,0,0,0-7.072-2,10.058,10.058,0,0,0,2.923,8ZM-1.1-1.032a.943.943,0,0,1-1.044-.957A.941.941,0,0,1-1.1-2.956H6.945a.933.933,0,0,1,1.034.967.935.935,0,0,1-1.034.957Z' transform='translate(7.072 12.004)' fill='%23fff'/%3E%3C/svg%3E"); } }

.plus {
    background: $plus-linear-gradient;
    &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M2.923,8a10.061,10.061,0,0,0,10-10A10.071,10.071,0,0,0,2.914-12,10.052,10.052,0,0,0-7.072-2,10.058,10.058,0,0,0,2.923,8ZM2.9,2.8a.933.933,0,0,1-.947-1.034v-2.8H-1a.941.941,0,0,1-1.044-.947A.941.941,0,0,1-1-2.956H1.957V-5.914A.941.941,0,0,1,2.9-6.958.953.953,0,0,1,3.88-5.914v2.958H6.848a.933.933,0,0,1,1.034.967.933.933,0,0,1-1.034.947H3.88v2.8A.95.95,0,0,1,2.9,2.8Z' transform='translate(7.072 12.004)' fill='%23fff'/%3E%3C/svg%3E"); } }

.text {
    color: var(--second-color);
    padding: 0;
    border: 0;
    box-shadow: none;
    background: none;
    &:focus {
        outline: 0; } }

// icons
.icon {
    border-radius: 12px;
    border: 0;
    &.video {
        width: 100%;
        height: 60px;
        background-color: transparent;
        transition: transform .1s;
        padding: 0; }
    &.videoControl {
        width: 100%;
        height: 40px; }
    &:focus {
        outline: 0; } }

.copy {
    width: 100%;
    padding: 18px 0;
    border: 0;
    opacity: 0.4;
    background-color: transparent;
    &:hover {
        opacity: 1; }
    &:focus {
        outline: 0; } }

.trash {
    padding: 1rem;
    background-color: transparent;
    border: 0;
    &:focus {
        outline: 0; } }

.clear {
    padding: 0;
    border: 0;
    background-color: transparent;
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
    &:focus {
        outline: 0; }
    .svg {
        width: 30px;
        height: 30px; } }

.svg {
    width: 20px;
    height: 20px; }

.svgMd {
    width: 30px;
    height: 30px; }

.rotate {
    transform: rotate(180deg); }

@media ( max-width: $mediaMobMinEdge ) {
    .default {
        padding-left: 0;
        padding-right: 0;
        width: 100%; } }

