$maxIndex: 100;

$color-initial: #2B3748;
$color-black: #000000;
$color-white: #ffffff;
$color-gray-light: #fafafa;
$color-gray: #a3a3a3;
$color-blue-dark: #0780E3;
$color-blue-light: #5AC8FA;
$color-red: #e03838;
$color-green: #4BD84B;
$color-green-dark: #2a8d32;

$color-notify-text: #3086a5;
$color-notify-background: rgba(#59caeb, 0.14);
$color-notify-border: rgba(#59caeb, 0.18);

$color-success-text: #155724;
$color-success-background: #d4edda;
$color-success-border: #c3e6cb;

$color-error-text: #721c24;
$color-error-background: #f8d7da;
$color-error-border: #f5c6cb;

$color-warning-text: #856404;
$color-warning-background: #fff3cd;
$color-warning-border: #ffeeba;

$background-input: #f4f6f7;
$background-input-disabled: #e2eaef;
$background-input-color-disabled: #9197a0;

$nav-shadow: 0 2px 10px -7px rgba($color-black, 0.12);
$accordion-shadow: 0 2px 10px -4px rgba($color-black, 0.12);
$block-shadow: 0 0 8px rgba($color-black, 0.02);
$dropdown-shadow: 0 0 10px rgba($color-black, 0.16);
$dish-shadow: 1px 1px 5px rgba($color-black, 0.05);

$blue-linear-gradient: linear-gradient(to right, #0780E3, #5AC8FA);
$green-linear-gradient: linear-gradient(135deg, #4BD84B, #5AC8FA);
$gray-linear-gradient: linear-gradient(to right, #a3a3a3, #D1D3D7);
$gray-blue-linear-gradient: linear-gradient(to right, #95aec1, #bdd1da);

$minus-linear-gradient: linear-gradient(-210deg, #E54A64, #FA9D31);
$plus-linear-gradient: linear-gradient(-210deg, #4BD84B, #46B6F2);

$link-gradient: linear-gradient(to left, #4BD84B, #46B6F4);
$link-gradient-hover: linear-gradient(to left, #46B6F4, #46B6F4);
$link-gradient-dragging: linear-gradient(to left, transparent, #46B6F4);

$preview-search-bg: #EEEEF0;
$preview-search-color: #838388;

@mixin dashed-image-loader {
    border: 2px dashed #bec3c5;
    background-color: $background-input; }

$transition: 0.2s;

@mixin gradientToRight( $accent, $second ) {
    background: linear-gradient(to right, $second, $accent); }

$mediaDeskEdge: 1199.98px;
$mediaTabEdge: 991.98px;
$mediaMobEdge: 767.98px;
$mediaMobMinEdge: 575.98px;
