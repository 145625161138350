.list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0; }

.item {
    cursor: pointer;
    padding: 6px 10px;
    margin-right: 10px;
    list-style: none;
    border-radius: 40px;
    background-color: #faefe0;
    min-width: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 8px;
        height: 19px;
        margin-right: 4px;
        fill: currentColor; }
    &.active {
        background-color: #34c759;
        color: #fff; } }
