@import "../../../styles/variables";

.card {
    padding: 20px 30px;
    border-radius: 10px;
    background: $gray-blue-linear-gradient;
    box-shadow: 0px 0px 0px #d6d7d8;
    color: $color-white;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    &.active {
        box-shadow: 3px 3px 4px #d6d7d8;
        @include gradientToRight(var(--accent-color), var(--second-color));
 } }        // background: $blue-linear-gradient

.title {
    text-transform: uppercase; }

.cardNumber {
    font-size: 1.75rem;
    font-weight: 700; }

.bankLogo {
    width: 68px; }
