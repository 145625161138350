@import "../../styles/variables";

.listItem {
    padding: 0.5rem 1rem;
    border: 2px solid transparent;
    cursor: pointer;
    margin-bottom: 0.5rem;
    box-shadow: $accordion-shadow;
    &:hover {
        color: var(--second-color); }
    &.red {
        border-color: #ffe5e5;
        background-color: #fff3f3; } }
