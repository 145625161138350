@import "../../styles/variables";

.description {
    font-weight: 300;
    color: $color-gray;
    max-width: 80%; }

.widthLimit {
    max-width: 60%; }

.widthLimitless {
    max-width: 100%; }

.fzLg {
    font-size: 1.25rem; }

.fzSm {
    font-size: 0.875rem; }

.blockAdd {
    color: $color-white;
    opacity: 0.7;
    height: 2rem;
    overflow: hidden;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 10px; }
