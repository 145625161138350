@import "../../styles/variables";

.videoWrapper {
    border-radius: 0.75rem;
    overflow: hidden;
    position: relative;
    &::after {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 56.25%; } }

.video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #F2F2F2; }

.timePos {
    position: absolute;
    top: 0;
    left: -1px;
    width: 2px;
    height: 100%;
    cursor: pointer;
    background-color: $color-white;
    z-index: 1;
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 26px;
        position: absolute;
        left: 0;
        bottom: 100%;
        background: var(--second-color);
        opacity: 0.3; }
    &::after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        margin-bottom: 12px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 100%;
        background: var(--second-color); } }

.sliderWrapper {
    position: relative;
    user-select: none; }

.sliderOverlay {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px; }

.timeWrapper,
.control {
    user-select: none; }
