@import "../../../styles/variables";

.tooltip {
    position: relative;
    display: inline-block; }

.tooltipText {
    position: absolute;
    font-size: 0.875rem;
    background-color: #333;
    color: $color-white;
    padding: 5px 9px;
    border-radius: 5px;
    width: 350px;
    max-width: 90vw;
    &::after {
        content: '';
        display: block;
        border: 8px solid transparent;
        position: absolute; } }
.top {
    color: #fff;
    .tooltipText {
        left: 50%;
        bottom: 100%;
        transform: translate(-50%, -8px);
        &::after {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-bottom-width: 0;
            border-top-color: #333; } } }
