@import "../../styles/variables";

.widgets {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    font-weight: 700; }

.icon {
    margin-right: 5px; }

.description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    color: #8e8e93;
    font-weight: 700;
    min-height: 42px;
    font-size: 0.875rem; }
