.schedule {
    &:hover {
        .copy {
            display: block; } } }

.copy {
    display: none;
    margin-bottom: 1rem; }

.day {
    font-size: 1.75rem;
    margin-bottom: 1rem; }
