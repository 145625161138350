@import "../../../../styles/variables";

.preview {
    background-color: $background-input;
    padding: 20px;
    border-radius: 12px;
    min-height: calc(100% - 1rem); }

.title {
    text-align: center;
    font-size: 1.325rem; }

.type {
    font-size: 0.875rem;
    text-align: center; }

.logo {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
    object-fit: contain;
    border-radius: 50%; }

.link {
    width: 100%;
    // background: $link-gradient
    background: linear-gradient(to right, var(--second-color), var(--accent-color));
    color: $color-white;
    padding: 15px 50px;
    border-radius: 12px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
    position: relative;
    &.dragging {
        // opacity: 0.6
        // background: linear-gradient(to left, transparent, var(--accent-color))
        z-index: $maxIndex; }
    &:hover,
    &:focus {
        background: var(--accent-color);
        .drag,
        .delete {
            opacity: 1; } } }

.drag {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 18px;
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3; }

.lines {
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: $color-white;
    position: relative;
    &::before,
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background-color: $color-white; }
    &::before {
        top: -6px; }
    &::after {
        bottom: -6px; } }

.delete {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.3; }
