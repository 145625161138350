@import "../../styles/variables";

.pageTitle {
    font-weight: 400;
    // font-size: 2.5rem
    font-size: 1.5rem; }

.blockTitle {
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 14px; }

.modalTitle {
    font-weight: 400;
    // font-size: 3rem
    font-size: 1.5rem;
    // color: #eaeaea
    margin: 0; }

.defaultTitle {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 0.75rem; }

.sectionTitle {
    font-size: 1.75rem;
    font-weight: 400; }

.notifyTitle {
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
    font-weight: 400; }

.back {
    display: block;
    width: 35px;
    height: 35px; }

@media ( max-width: $mediaMobEdge ) {
    .modalTitle,
    .pageTitle {
        font-size: 2rem; } }

@media ( max-width: $mediaMobMinEdge ) {
    .modalTitle,
    .pageTitle {
        font-size: 1.75rem; }

    .back {
        width: 30px;
        height: 30px; } }

