@import "../../../styles/variables";

.contextMenu {
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;
    background-color: $color-white;
    border-bottom-left-radius: inherit;
    border-top-right-radius: inherit;
    cursor: pointer;
    &.invisible {
        background-color: transparent;
        left: 0;
        right: auto; }
    &.transparent {
        background-color: transparent;
        .toggler {
            &::before,
            &::after {
                background-color: $color-white; } } } }

.toggler {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    &::before,
    &::after {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        position: absolute;
        top: 17px;
        left: 14px;
        border-radius: 50%;
        background-color: $color-gray; }
    &::after {
        top: 25px; } }

.menu {
    position: absolute;
    top: 100%;
    right: -15px;
    padding: 14px 20px;
    background-color: $color-white;
    box-shadow: 0 0 10px rgba($color-black, 0.06);
    border-radius: 12px;
    z-index: 1; // index for menu block
    &.visible {
        visibility: visible;
        opacity: 1;
        right: auto;
        left: 0;
        top: 0; } }

.item {
    display: flex;
    align-items: center;
    color: $color-blue-dark;
    padding: 4px 0;
    white-space: nowrap; }

.icon {
    width: 20px;
    margin-right: 6px;
    svg {
        display: block;
        margin: auto;
        max-width: 20px;
        max-height: 20px; } }

.red {
    color: $color-red; }

.delimiter {
    width: 100%;
    height: 1px;
    background-color: #f1f1f3; }
