@import "../../styles/variables";

.login {
    background-color: rgba($color-white, 0.52);
    backdrop-filter: blur(10px);
    max-width: 800px;
    margin: auto;
    border-radius: 3px;
    padding: 50px 44px;
    width: 100%; }
