@import "../../../styles/variables";

.update {
    padding-left: 28px;
    padding-bottom: 38px;
    position: relative;
    &::before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: $color-green;
        position: absolute;
        top: 8px;
        left: 0; }
    &:last-child {
        &::before {
            background: linear-gradient(180deg, $color-green, transparent); } }
    &::after {
        content: '';
        width: 11px;
        height: 11px;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 5px;
        left: -5px;
        background: $color-green; } }

.date_create {
    margin-right: 12px; }

.description {
    color: $color-blue-dark; }

.title {
    margin-top: 20px;
    margin-bottom: 12px; }

.list {
    margin: 0;
    padding-left: 0; }

.item {
    list-style: none;
    position: relative;
    padding-left: 14px;
    padding-bottom: 10px;
    line-height: 1.15;
    &::before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        position: absolute;
        left: 0;
        top: 5px;
        background: $color-blue-dark;
        border-radius: 2px; }
    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: $color-blue-dark;
        position: absolute;
        top: 5px;
        left: 3px; }
    &:last-child {
        &::after {
            content: normal; } } }

@media ( max-width: $mediaMobMinEdge ) {
    .update {
        font-size: 0.875rem; } }
