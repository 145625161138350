@import "../../../styles/variables";

.plan {
    box-shadow: 0 0 0 0 rgba(#000, 0.05);
    border-radius: 1.5rem;
    padding: 2rem;
    background-color: $color-white;
    max-width: 950px; }

.sale {
    color: $color-red;
    font-weight: 600; }

.transparent {
    opacity: 0; }

.price {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 0; }

.priceOld {
    font-size: 1.5625rem;
    font-weight: 500;
    color: $color-gray;
    text-decoration: line-through; }

.dateFinish {
    color: $color-green-dark; }

.list {
    padding-left: 0;
    margin-bottom: 0; }

.item {
    list-style: none;
    line-height: 24px; }

.svg {
    fill: $color-green;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-bottom: 3px;
    vertical-align: middle; }

@media ( max-width: $mediaMobEdge ) {
    .list {
        margin-bottom: 20px; }

    .plan {
        padding: 20px; }

    .price {
        line-height: 1.15; } }

