@import "../../../styles/variables";

.dish {
    box-shadow: $dish-shadow;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 12px; }

.title {
    margin-bottom: 0;
    font-weight: 700; }

.count {
    color: $color-red;
    margin-right: 6px;
    font-weight: 700; }

.gray {
    color: $color-gray; }
