@import "../../../styles/variables";

.alert {
    padding: 1.25rem 1.875rem 0.25rem;
    border: 1px solid currentColor;
    border-radius: 10px;
    &.notify {
        color: $color-notify-text;
        border-color: $color-notify-border;
        background-color: $color-notify-background; }
    &.success {
        color: $color-success-text;
        border-color: $color-success-border;
        background-color: $color-success-background; } }
