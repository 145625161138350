@font-face {
    font-family: 'SF Pro Display';
    src: url('./SFProDisplay-Regular.eot');
    src: url('./SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('./SFProDisplay-Regular.woff2') format('woff2'),
        url('./SFProDisplay-Regular.woff') format('woff'),
        url('./SFProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./SFProDisplay-RegularItalic.eot');
    src: url('./SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('./SFProDisplay-RegularItalic.woff2') format('woff2'),
        url('./SFProDisplay-RegularItalic.woff') format('woff'),
        url('./SFProDisplay-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./SFProDisplay-Semibold.eot');
    src: url('./SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
        url('./SFProDisplay-Semibold.woff2') format('woff2'),
        url('./SFProDisplay-Semibold.woff') format('woff'),
        url('./SFProDisplay-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./SFProDisplay-Medium.eot');
    src: url('./SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('./SFProDisplay-Medium.woff2') format('woff2'),
        url('./SFProDisplay-Medium.woff') format('woff'),
        url('./SFProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'SF Pro Display';
}