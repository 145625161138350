@import "../../../styles/variables";

.notifications {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 20px;
    margin-bottom: 0;
    z-index: #{$maxIndex+1}; }

.notification {
    max-width: 450px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
    padding: 24px 18px 18px;
    border: 1px solid currentColor;
    overflow: hidden;
    &.notify {
        color: $color-notify-text;
        border-color: $color-notify-border;
        background-color: $color-notify-background; }
    &.success {
        color: $color-success-text;
        border-color: $color-success-border;
        background-color: $color-success-background; }
    &.error {
        color: $color-error-text;
        border-color: $color-error-border;
        background-color: $color-error-background; }
    &.warning {
        color: $color-warning-text;
        border-color: $color-warning-border;
        background-color: $color-warning-background; } }

.close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 28px;
    height: 28px;
    cursor: pointer;
    &::before,
    &::after {
        content: '';
        display: block;
        width: 50%;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: currentColor; }
    &::before {
        transform: translate(-50%, -50%) rotate(45deg); }
    &::after {
        transform: translate(-50%, -50%) rotate(-45deg); } }

.text {
    margin-bottom: 0;
    opacity: 0.7; }

.progress {
    background-color: currentColor;
    opacity: 0.7;
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
