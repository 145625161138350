@import "../../styles/variables";

.switch {
    border-radius: 18px;
    width: 51px;
    height: 31px;
    position: relative;
    border: 2px solid transparent;
    &.inactive {
        background-color: $color-gray !important; } }

.toggler {
    width: 27px;
    height: 27px;
    border-radius: 13px;
    background-color: #ffffff;
    position: absolute;
    top: 0; }
