@import "../../styles/variables";

.waiterImgRemover {
    max-width: 266px; }

.description {
    margin-bottom: 1rem;
    color: $color-gray; }

.red {
    color: $color-red; }

.back {
    color: $color-blue-dark;
    cursor: pointer; }
